import Card from "@mui/material/Card";
import "./Login.css";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React from "react";
import { useSnackbars } from "@/parts/notification";
import api_client from "@/api_client";
import TextField from "@mui/material/TextField";
import EmailOutlined from "@mui/icons-material/EmailOutlined";
import Password from "@mui/icons-material/Password";
import IconButton from "@mui/material/IconButton";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import LoadingButton from "@mui/lab/LoadingButton";

export const Login = () => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [rememberMe, setRememberMe] = React.useState(false);

  const [showPassword, setShowPassword] = React.useState(false);

  const [isLoading, setIsLoading] = React.useState(false);

  const notify = useSnackbars();

  const focusEl = (id: string) => {
    const el = document.getElementById(id) as HTMLInputElement;
    el.focus();
  };

  const submitLogin = async () => {
    setIsLoading(true);
    try {
      await api_client.post("/users/sign_in", {
        user: {
          email: email,
          password: password,
          remember_me: rememberMe ? 1 : 0,
        },
      });
      const urlParams = new URLSearchParams(window.location.search);
      const refer = urlParams.get("refer");
      if (refer) {
        window.location.assign(refer);
      } else {
        window.location.assign("/");
      }
    } catch (e) {
      notify.prefixError("", e);
      focusEl("email-field");
    }
    setIsLoading(false);
  };

  const handleSubmitEmail = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key != "Enter") return;
    focusEl("password-field");
  };

  const handleSubmitPassword = async (
    e: React.KeyboardEvent<HTMLDivElement>
  ) => {
    if (e.key != "Enter") return;
    await submitLogin();
  };

  return (
    <Card className={"center-card"} elevation={3}>
      <Grid container spacing={1} direction={"column"}>
        <Grid item>
          <Typography variant={"h6"} align={"center"}>
            Log in
          </Typography>
        </Grid>
        <Grid item>
          <div id={"notice-inject"} />
          <div id={"alert-inject"} />
        </Grid>
        <Grid item>
          <TextField
            fullWidth
            label={"Email"}
            autoFocus
            InputProps={{
              startAdornment: <EmailOutlined />,
            }}
            id={"email-field"}
            onKeyUp={handleSubmitEmail}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Grid>
        <Grid item>
          <TextField
            fullWidth
            label={"Password"}
            type={showPassword ? "text" : "password"}
            id={"password-field"}
            onKeyUp={handleSubmitPassword}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              startAdornment: <Password />,
              endAdornment: (
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  tabIndex={-1}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              ),
            }}
          />
        </Grid>
        <Grid item container justifyContent={"space-between"}>
          <Grid item>
            <FormGroup>
              <FormControlLabel
                sx={{ userSelect: "none" }}
                control={
                  <Checkbox
                    sx={{ padding: "0px" }}
                    checked={rememberMe}
                    onClick={(_) => setRememberMe(!rememberMe)}
                  />
                }
                label={"Remember Me"}
              />
            </FormGroup>
          </Grid>
          <Grid item>
            <FormControlLabel
              sx={{ margin: "0px" }}
              control={<div style={{ padding: "20px" }} />}
              label={<a href={"/accounts/forgot_password"}>Forgot Password</a>}
            />
          </Grid>
        </Grid>
        <Grid item>
          <Divider />
        </Grid>
        <Grid item>
          <LoadingButton
            loading={isLoading}
            onClick={submitLogin}
            fullWidth
            variant={"contained"}
          >
            Log In
          </LoadingButton>
        </Grid>
        <br />
        <Grid item container justifyContent={"center"}>
          <Grid item>&copy; Standard Industries</Grid>
        </Grid>
      </Grid>
    </Card>
  );
};
